
import { parse } from 'node-html-parser';


/**
 * This exists mainly to preprocess raw ghost post HTML to make it fit the frontend better
 */
export function transformGhostHTML(html: string) {
    const root = parse(html);

    const audioContainers = root.querySelectorAll(".kg-audio-card")

    for(const ac of audioContainers) {
        // we want to strip out ghosts audio controls and add some IDs so we can target this with our audio player lib
    
        const audio = ac.querySelector("audio")

        if(audio) {
            audio.setAttribute("controls", "")
            ac.set_content("")
            ac.appendChild(audio)
        } else {
            console.error("can't find audio element within the ghost audio card")
        }
        
    }

    

    return root.toString()
}