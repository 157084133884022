import { AutoStories, Close, Speaker, VolumeUp } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import useGhost from "../blog/useGhost";
import CommentsSection from "../components/comments/CommentsSection";
import trackEvent from "../services/analyticsService";
import { transformGhostHTML } from "../helpers/ghostTransformer";
import GhostPostBody from "../components/GhostPostBody";

export default function PoetryList() {
  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      useGhost.getState().requestPost(slug);
    }
  }, [slug]);

  const loadedPosts = useGhost((state) => state.posts);

  const poems = useGhost((state) => state.poemList);

  useEffect(() => {
    useGhost.getState().requestPoemList();
  }, []);

  const [open, setOpen] = useState<string | undefined>(undefined);

  useEffect(() => {
    setOpen(slug);
  }, [slug]);

  const currentPoem = slug === undefined ? undefined : loadedPosts[slug];

  const navigate = useNavigate();

  const theme = useTheme();

  // TODO: change this if I end up using SSR
  // this is mainly needed as two comment sections cause issues
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true
  });

  useEffect(() => {
    // TODO: restructure this whole component
    // to use scroll/routes more naturally
    const wrapper = document.getElementById("content-wrapper");

    if (!wrapper) {
      console.error("Cannot find content wrapper to control scroll");
    } else {
      // This will reset the gallery to the top as well, which isn't ideal
      // but better than setting gallery to the poem scroll or the poem
      // scroll getting set to the gallery scroll
      wrapper.scrollTo(0, 0);
    }

  }, [currentPoem]);

  return (
    <>
      {smallScreen && currentPoem && currentPoem.html && (
        <Box
          component="article"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2
          }}
        >
          <Typography variant="h4" textAlign="center">
            {currentPoem.title}
          </Typography>

          <GhostPostBody post={currentPoem}/>

          <Box
            component="img"
            src={currentPoem.feature_image || undefined}
            alt={currentPoem.feature_image_alt || undefined}
            sx={{
              mt: 2,
              mb: 2,
              maxWidth: "65%"
            }}
          />

          <CommentsSection resource={`/poetry/${currentPoem.slug}`} />
        </Box>
      )}

      {!(smallScreen && currentPoem) && (
        <Box
          component={slug ? "div" : "main"}
          aria-label={
            slug
              ? undefined
              : "Image gallery of AI-generated artwork, each representing a poem. Click the artwork/link to read the poem."
          }
          sx={{
            display: "flex",
            flexWrap: "wrap",
            minHeight: "100%",
            alignItems: "flex-start",
            alignContent: "flex-start",
            // columnGap: 3,
            // rowGap: 3,
            gap: {
              xs: 1,
              sm: 3
            },
            p: {
              xs: 0,
              sm: 3
            },
            pt: {
              xs: 2,
              sm: 3
            },
            justifyContent: "center",
            mb: 2
          }}
        >
          {poems.map((poem) => (
            <Box
              key={poem.slug}
              sx={{
                maxWidth: "300px",
                width: "47.5%",
                aspectRatio: "1/1",
                height: "auto",
                cursor: "pointer"
              }}
            >
              <Paper
                className="card"
                elevation={7}
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  "&:hover .emphasis-on-hover": {
                    transform: "scale(1.2)",
                    color: "primary.main"
                  },
                  "&:hover": {
                    borderColor: "secondary.main"
                  },
                  transition: "all 0.3s",
                  border: 1,
                  borderRadius: 1,
                  borderColor: "transparent",
                  overflow: "hidden"
                }}
              >
                {/** If this is just in the background, we don't want links, as crawlers will hit them (and they're not
                 * clickable by users anyways) */}
                {slug && (
                  <Box sx={{ width: "100%", height: "100%" }}>
                    <img
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={poem.feature_image || undefined}
                      alt={poem.feature_image_alt || undefined}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "rgba(0,0,0,0.70)",
                        p: 1,
                        color: "white"
                      }}
                    >
                      <Typography component="header" textAlign="center">
                        {poem.title}
                      </Typography>
                    </Box>
                  </Box>
                )}

                {/* if we're on the full gallery, we want links */}
                {!slug && (
                  <Link
                    aria-label={poem.title}
                    sx={{ width: "100%", height: "100%" }}
                    component={RouterLink}
                    to={poem.slug}
                    onClick={() => {
                      trackEvent({
                        event: "Clicked Poem Image",
                        properties: {
                          path: `/poetry/${poem.slug}`,
                          title: poem.title,
                          imagePath: poem.feature_image
                            ? new URL(poem.feature_image).pathname
                            : undefined
                        }
                      });
                    }}
                  >
                    <img
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={poem.feature_image || undefined}
                      alt={poem.feature_image_alt || undefined}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "rgba(0,0,0,0.70)",
                        p: 1,
                        color: "white"
                      }}
                    >
                      <Typography
                        sx={{ margin: "auto", maxWidth: "70%" }}
                        component="header"
                        textAlign="center"
                      >
                        {poem.title}
                      </Typography>
                    </Box>
                  </Link>
                )}

                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "rgba(0,0,0,0)",
                    p: 1,
                    color: "white",
                    display: "flex",
                    gap: 1
                  }}
                >
                  
                    <AutoStories
                      className="emphasis-on-hover"
                      sx={{
                        verticalAlign: "middle",
                        fontSize: "1em",
                        transition: "all 0.3s"
                      }}
                    />
                    {poem?.tags?.some(t => t.name === "audio") && 
                  <VolumeUp
                      className="emphasis-on-hover"
                      sx={{
                        verticalAlign: "middle",
                        fontSize: "1em",
                        transition: "all 0.3s"
                      }}
                    />
}
                </Box>
              </Paper>
            </Box>
          ))}
        </Box>
      )}
      {!smallScreen && (
        <Dialog
          open={Boolean(open)}
          onClose={() => {
            setOpen(undefined);
            navigate("/poetry");
          }}
        >
          {currentPoem && currentPoem.html && (
            <>
              <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
                {currentPoem.title}
                <Link
                  component={RouterLink}
                  to="/poetry"
                  aria-label="Return to poetry gallery"
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      borderRadius: 1
                    }}
                    onClick={() => {
                      setOpen(undefined);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Link>
              </DialogTitle>
              <DialogContent>
                <Box
                  component="main"
                  sx={{
                    width: "500px",
                    maxWidth: "min(100%, 500px)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <GhostPostBody post={currentPoem} />
                  
                  <CommentsSection resource={`/poetry/${currentPoem.slug}`} />
                </Box>
              </DialogContent>
            </>
          )}
        </Dialog>
      )}
    </>
  );
}
