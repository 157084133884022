import { Box } from "@mui/material";
import { PostOrPage } from "@tryghost/content-api";
import { transformGhostHTML } from "../helpers/ghostTransformer";
import useDarkMode from "../helpers/useDarkMode";
import { useEffect } from "react";
import hljs from "highlight.js";
import "./GhostPostBody.scss"

interface GhostPostBodyProps {
    post: PostOrPage
}

export default function GhostPostBody(props: GhostPostBodyProps) {

    const darkMode = useDarkMode();

    if(!props.post.html) {
        return <Box>can't load post :(</Box>
    }

    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return(
    <Box 
      className="ghost-containment-unit"
      data-theme={darkMode ? "dark" : "light"}
      dangerouslySetInnerHTML={{ __html: transformGhostHTML(props.post.html) }}
      
      sx={{
                  "& img": {
                    height: "auto",
                    width: "auto",
                    maxWidth: "100%",
                    maxHeight: "450px"
                  },
                  "& .kg-image-card": {
                    display: "flex",
                    justifyContent: "center"
                  },
                  "& .kg-callout-card": {
                    //display: "flex",
                    columnGap: 2,
                    m: {
                      xs: 0,
                      sm: 1
                    },
                    p: 1,
                    pl: 2,
                    pr: 2,
                    border: 1,
                    borderRadius: 1,
                    borderColor: "primary.main"
                  },
                  "& .kg-callout-emoji": {
                    fontSize: "1.5em",
                    pt: 1,
                    pr: 2,
                    float: "left"
                  },
                  "& .kg-callout-card-yellow": {
                    borderColor: "warning.main"
                  },
                  "& .kg-audio-card audio": {
                    width: "100%"
                  }
                }}
              ></Box>)

    
}